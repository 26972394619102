import {POST, GET} from './request'

export const register = (data) => POST('/api/login/register', data)
export const login = (data) => POST('/api/login/login', data)
export const CustomerService = () => GET('/api/basic/service')
export const UserAgreement = () => GET('/api/basic/user')
export const Privacy = () => GET('/api/basic/privacy')
export const UserInfo = () => GET('/api/user/index')
export const Logout = () => POST('/api/login/logout')
export const Recharge = (data) => POST('/api/wallet/recharge', data)
export const Wallet = () => GET('/api/wallet/index')
export const BankList = () => GET('/api/bank/index')
export const LevelInfo = () => GET('/api/user/level')
export const SignInData = () => GET('/api/sign_in/index')
export const SignIn = () => GET('/api/sign_in/sign_in')
export const BroadcastList = () => GET('/api/basic/broadcast')
export const UpdateBank = (data) => POST('/api/bank/update', data)
export const OrderList = params => GET('/api/order/list', params)
export const Activity = params => GET('/api/basic/activity', params)
export const BillList = params => GET('/api/wallet/bill', params)
export const Withdraw = data => POST('/api/wallet/withdraw', data)
export const Buy = data => POST('/api/product/buy', data)
export const ConfirmOrder = data => POST('/api/order/submit', data)
export const UpdatePwd = data => POST('/api/user/updatePassword', data)
export const WithdrawalInstructions = () => GET('/api/basic/withdrawal_instructions')
export const RechargeInstructions = () => GET('/api/basic/recharge_instructions')
