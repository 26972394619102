import axios from 'axios'
import { showToast } from 'vant';
import { baseURL } from '@/config';
import { router } from '@/router';

const instance = axios.create({ baseURL })

export const POST = (url, data) => {
    return instance.post(url, data)
}

export const GET = (url, params) => {
    return instance.get(url, { params })
}

// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    if (user) {
        config.headers['token'] = user.token
    }
    config.headers['language'] = {'en_US': 'en', 'thi_IN': 'hindi'}[user?.lang] || 'en'
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use((response) => {
    if (response.data.code === 401) {
        router.push('/index')
    }
    return response.data
}, function (error) {
    showToast(error.response.data.message)
    return Promise.reject(error)
})
